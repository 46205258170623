
const PrivacyPolicyFR = () => {
   return (
       <div align="left">
           <>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: "25.9pt",
                       textAlign: "left",
                       lineHeight: "107%"
                   }}
               >
                   <strong>
                       <span style={{ fontSize: 32, color: "#002368" }}>
                           HOMECARE
                       </span>
                   </strong>
               </p>
               <br/>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "7.0pt",
                       marginLeft: "25.9pt",
                       textAlign: "left",
                       lineHeight: "107%"
                   }}
               >
                   <strong>
                       <span style={{ fontSize: 32, color: "#002368" }}>
                           Politique en matière de vie privée&nbsp;
                       </span>
                   </strong>
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p style={{ marginRight: "0cm" }}>
                   Ce document constitue la politique en matière de traitement des données à
                   caractère personnel, applicable à l’utilisation de l’Application web.
               </p>
               <p style={{ marginRight: "0cm" }}>&nbsp;</p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>
                   MISE A JOUR DE LA PRESENTE POLITIQUE
               </h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "36.5pt"
                   }}
               >
                   AXA ASSISTANCE peut mettre à jour cette Politique en réponse à des
                   changements juridiques, techniques ou commerciaux. Lors des mises à jour,
                   AXA ASSISTANCE prendra les mesures appropriées pour vous en informer, au
                   regard de l'importance de ces changements. Lorsque cela est requis par la
                   loi, AXA ASSISTANCE sollicitera votre consentement pour toute modification
                   importante des traitements de vos données à caractère personnel. Cette
                   Politique a été mise à jour pour la dernière fois le 28 AOUT 2024.
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>
                   MENTIONS LÉGALES – RESPONSABLE DU TRAITEMENT&nbsp;
               </h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   SA INTER PARTNER ASSISTANCE &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Membre du Groupe AXA ASSISTANCE&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Siège social : Boulevard du Régent 7, 1000 Bruxelles&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "6.2pt",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Immatriculée à la Banque Carrefour des Entreprises sous le n° 0415.591.055,
                   RPM Bruxelles Entreprise d’assurance agréée sous le code 0487&nbsp;
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   (ci-après dénommée <em>« AXA ASSISTANCE »</em>).&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "56.65pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>DEFINITIONS&nbsp;</h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   L’Editeur attire l’attention des Utilisateurs de son site sur les points
                   ci-après : &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "1.1pt",
                       marginLeft: "36.0pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <ul style={{ listStyleType: "undefined margin-left: 53.85px" }}>
                   <li>
                       <em>« Application web»</em> désigne <em>HOMECARE</em>.&nbsp;
                       <span style={{ lineHeight: "107%", fontSize: 11 }}>
                           <a
                               id="_anchor_1"
                               href="#_msocom_1"
                               language="JavaScript"
                               name="_msoanchor_1"
                           >
                               [BS1]
                           </a>
                           &nbsp;
                       </span>
                   </li>
                   <li>
                       <em>« AXA ASSISTANCE »</em> désigne la SA INTER PARTNER ASSISTANCE,
                       décrite ci-dessus ;&nbsp;
                   </li>
                   <li>
                       <em>« Données »</em> désigne les données à caractère personnelle ;&nbsp;
                   </li>
                   <li>
                       <em>« Editeur »</em> désigne AXA ASSISTANCE ; &nbsp;
                   </li>
                   <li>
                       <em>« Législation »</em> désigne les législations et réglementations
                       belges, européennes et internationales, relatives à la protection de la
                       vie prive et au traitement des données personnelles, notamment le RGPD et
                       les lois et arrêtés belges qui complète ce Règlement ;&nbsp;
                   </li>
                   <li>
                       <em>« Responsable du Traitement »</em> désigne le responsable du
                       traitement au sens donné par le RGPD ; le responsable du traitement est
                       AXA ASSISTANCE ;&nbsp;
                   </li>
                   <li>
                       <em>« RGPD »</em> désigne le Règlement (UE) 2016/679 du Parlement européen
                       et du Conseil du 27 avril 2016 relatif à la protection des personnes
                       physiques à l'égard du traitement des données à caractère personnel et à
                       la libre circulation de ces données, et abrogeant la directive 95/46/CE
                       ;&nbsp;
                   </li>
                   <li>
                       <em>« Sous-traitant »</em> désigne le sous-traitant, au sens donné par le
                       RGPD ;&nbsp;
                   </li>
                   <li>
                       <em>« Utilisateur »</em> désigne toute personne utilisant l’Application
                       mobile ; &nbsp;
                   </li>
                   <li>
                       <em>« Vous »</em> ou <em>« Bénéficiaire »</em> désignent le bénéficiaire
                       des prestations d’assistance (telles que définies dans les conditions
                       contractuelles de votre contrat ou de votre police), qui accède, consulte,
                       utilise l’Application mobile pour la mise en œuvre des Prestations
                       d’assistance.&nbsp;
                   </li>
               </ul>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>FINALITE DU TRAITEMENT&nbsp;</h1>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: "36.0pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Les données à caractère personnel que vous communiquez via l’Application Web
                   à AXA ASSISTANCE&nbsp;
                   <span style={{ fontSize: 11, lineHeight: "103%" }}>
                       <a
                           id="_anchor_2"
                           href="#_msocom_2"
                           language="JavaScript"
                           name="_msoanchor_2"
                       >
                           [BS2]
                       </a>
                       &nbsp;
                   </span>
                   peuvent être traitées par celle-ci pour les finalités suivantes : &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "1.1pt",
                       marginLeft: "7.7pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <ul style={{ listStyleType: "undefined margin-left: 53.85px" }}>
                   <li>La fourniture des services d’assistance,&nbsp;</li>
                   <li>
                       Le développement, la gestion, l’adaptation et la mise à jour de
                       l’Application mobile et/ou de ses fonctionnalités,&nbsp;
                   </li>
                   <li>
                       l’analyse (en ce compris l’analyse technique ou statistique) des usages de
                       l’Application mobile et/ou de ses fonctionnalités et des connexions à
                       l’Application,&nbsp;
                   </li>
                   <li>la gestion du fichier des personnes, &nbsp;</li>
                   <li>le service à la clientèle, &nbsp;</li>
                   <li>la détection, prévention et lutte contre la fraude, &nbsp;</li>
                   <li>
                       la lutte contre le blanchiment de capitaux et le financement du
                       terrorisme, &nbsp;
                   </li>
                   <li>la surveillance du portefeuille, &nbsp;&nbsp;</li>
                   <li>
                       le respect des législations applicables (fiscales, sociales, comptables,
                       etc.) aux services d’assistance prévus par votre contrat ou votre police
                       d’assurance.
                   </li>
                   <li>les études statistiques.&nbsp;</li>
               </ul>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "5.45pt",
                       marginBottom: ".25pt",
                       marginLeft: "36.5pt"
                   }}
               >
                   Dans la mesure où la communication des données à caractère personnel est
                   nécessaire pour permettre de réaliser les finalités énumérées ci-dessus, les
                   données à caractère personnel peuvent être communiquées à d’autres
                   entreprises membres du Groupe AXA, à des entreprises et/ou à des personnes
                   en relation avec celles- ci (avocats, experts, médecins conseils,
                   réassureurs, co-assureurs, intermédiaires d’assurances, prestataires de
                   services, autres entreprises d’assurances, représentants, bureau de suivi de
                   la tarification, bureaux de règlement de sinistres, Datassur).&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "5.45pt",
                       marginBottom: ".25pt",
                       marginLeft: "36.5pt"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "5.45pt",
                       marginBottom: ".25pt",
                       marginLeft: "36.5pt"
                   }}
               >
                   Ces données peuvent également être communiquées aux autorités de contrôle,
                   aux services publics compétents ainsi qu’à tout autre organisme public ou
                   privé avec lequel AXA ASSISTANCE peut être amenée à échanger des données à
                   caractère personnel conformément à la législation applicable.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "5.45pt",
                       marginBottom: ".25pt",
                       marginLeft: "36.5pt"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "27.55pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>
                   DONNEES TRAITEES VIA L’APPLICATION WEB
               </h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   AXA ASSISTANCE peut récolter, via l’Application web, les Données
                   Personnelles suivantes : vos nom et prénom, votre numéro de téléphone, votre
                   adresse, votre adresse mail, le type de service/ prestation demandé, votre
                   adresse IP, votre langue, vos données de connexion, ainsi que les données
                   relatives aux événements liés aux appareils que vous utilisez (activité du
                   système, date et heure de connexion, paramètre du matériel, problèmes
                   techniques, etc.).&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>BASES LEGALES DU TRAITEMENT&nbsp;</h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: "1.5pt",
                       textAlign: "center",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   AXA ASSISTANCE traite vos Données Personnelles en se fondant sur les bases
                   légales suivantes :&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "1.15pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <ul style={{ listStyleType: "undefined margin-left: 72.3px" }}>
                   <li>
                       L’exécution de votre contrat ou de votre police d’assurance ainsi que des
                       conditions générales d’utilisation de l’Application mobile : en vue de
                       vous fournir les prestations d’assistance, de gérer ou d’exécuter votre
                       contrat ou votre police, de gérer l’Application mobile,&nbsp;
                   </li>
                   <li>
                       La poursuite des intérêts légitimes d’AXA ASSISTANCE : en vue de gérer
                       l’Application mobile, de procéder aux analyses techniques ou statistiques,
                       en vue de gérer la relation avec l’assuré, le Bénéficiaire ou
                       l’Utilisateur, en vue de détecter, prévenir et lutter contre les fraudes,
                       en vue de surveiller son portefeuille ;&nbsp;
                   </li>
                   <li>
                       L’exécution d’obligation légale et réglementaire : en vue de respecter et
                       d’appliquer les législations applicables (fiscales, sociales, comptables,
                       etc.) aux services d’assistance prévus par votre contrat ou votre police
                       d’assurance.&nbsp;
                   </li>
               </ul>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Les Données Personnelles qu’AXA ASSISTANCE vous demande de fournir sont
                   nécessaires à la bonne utilisation de l’Application mobile et des services
                   d’assistance liés. Ne pas fournir ces données peut rendre impossible ou
                   difficile la conclusion ou la bonne utilisation de l’Application mobile, de
                   ses fonctionnalités et/ou des services d’assistance liés.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1>NECESSITE DE FOURNIR LES DONNEES A CARACTERE PERSONNEL</h1>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "5.45pt",
                       marginBottom: ".25pt",
                       marginLeft: "7.15pt"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "5.45pt",
                       marginBottom: ".25pt",
                       marginLeft: "36.5pt"
                   }}
               >
                   Les données à caractère personnel relatives à la personne concernée qu’AXA
                   Partners demande de fournir sont nécessaires à la conclusion et à
                   l’exécution du contrat d’assurance. Ne pas fournir ces données peut rendre
                   impossible la conclusion ou la bonne exécution du contrat d’assurance.&nbsp;
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".95pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>TRANSFERT DES DONNÉES &nbsp;</h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Dans la mesure où la communication des données à caractère personnel est
                   nécessaire pour permettre de réaliser les finalités énumérées ci-dessus, AXA
                   ASSISTANCE peut transférer vos Données Personnelles à d’autres personnes,
                   qu’il s’agisse des autres entreprises du Groupe AXA ou de tiers à d’autres
                   entreprises membres du Groupe AXA, à des entreprises et/ou à des personnes
                   en relation avec celles-ci (prestataires de services d’assistance, avocats,
                   experts, médecins conseils, réassureurs, co-assureurs, intermédiaires
                   d’assurances, prestataires de services, autres entreprises d’assurances,
                   représentants, bureau de suivi de la tarification, bureaux de règlement de
                   sinistres, Datassur). Les autres entreprises du Groupe AXA, les entreprises
                   et/ou les personnes en relation avec celles-ci auxquelles les Données
                   Personnelles sont communiquées, peuvent être situées aussi bien dans l’Union
                   Européenne qu’en dehors.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   En cas de transferts de données à caractère personnel à des tiers situés en
                   dehors de l’Union Européenne, AXA ASSISTANCE se conforme aux dispositions
                   légales et réglementaires en vigueur en matière de tels transferts. Elle
                   assure, notamment, un niveau de protection adéquat aux données à caractère
                   personnel ainsi transférées sur la base des mécanismes alternatifs mis en
                   place par la Commission européenne, tels les clauses contractuelles
                   standard, ou encore les&nbsp;
                   <a href="https://www.axa.com/fr/a-propos-d-axa/confidentialite-donnees#tab=nos-engagementshttps%3A%2F%2F">
                       règles d’entreprise contraignantes
                   </a>
                   <a
                       id="_anchor_3"
                       href="#_msocom_3"
                       language="JavaScript"
                       name="_msoanchor_3"
                   >
                       [LV3]
                   </a>{" "}
                   <span style={{ fontSize: 11, lineHeight: "103%" }}>
                       <a
                           id="_anchor_4"
                           href="#_msocom_4"
                           language="JavaScript"
                           name="_msoanchor_4"
                       >
                           [BS4]
                       </a>
                       &nbsp;
                   </span>{" "}
                   du Groupe AXA en cas de transferts intragroupe (<em>Mon. B.</em> 6/10/2014,
                   p. 78547).&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Ces données peuvent également être communiquées aux autorités de contrôle,
                   aux services publics compétents ainsi qu’à tout autre organisme public ou
                   privé avec lequel AXA ASSISTANCE peut être amenée à échanger des Données
                   Personnelles conformément à la législation applicable.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>CONSERVATION DES DONNÉES&nbsp;</h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   AXA ASSISTANCE conserve les Données Personnelles récoltées via l’Application
                   mobile pendant toute la durée de l’utilisation de cette Application et de la
                   fourniture des services d’assistance liés. Cette durée peut être prolongée
                   du délai légal de conservation (en matière comptable ou fiscale, notamment)
                   ou du délai de prescription de manière à pouvoir faire face aux demandes ou
                   aux éventuels recours qui seraient engagés après la fin de l’utilisation de
                   l’Application mobile ou après la clôture du dossier sinistre.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: "36.0pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   AXA ASSISTANCE s’engage à garder vos Données Personnelles de manière
                   sécurisée, confidentielle, exacte pendant la période d’utilisation autorisée
                   par les présentes. Au terme de la période de conservation, vos Données
                   Personnelles seront rendues anonymes ou détruites.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: ".5pt",
                       textAlign: "left",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   <span style={{ fontSize: 19, color: "red" }}>
                       &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                   </span>
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>CONFIDENTIALITÉ&nbsp;</h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   AXA ASSISTANCE a pris toutes les mesures nécessaires afin de préserver la
                   confidentialité des Données Personnelles et afin de se prémunir contre tout
                   accès non autorisé, toute mauvaise utilisation, modification ou suppression
                   de celles-ci.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   A cette fin, AXA ASSISTANCE suit les standards de sécurité et de continuité
                   de service et évalue régulièrement le niveau de sécurité de ses processus,
                   systèmes et applications ainsi que ceux de ses partenaires.&nbsp;
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>
                   DROITS DE LA PERSONNE CONCERNÉE&nbsp;
               </h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Vous avez le droit :&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "1.15pt",
                       marginLeft: "17.9pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <ul style={{ listStyleType: "undefined margin-left: 53.85px" }}>
                   <li>
                       d'obtenir d’AXA ASSISTANCE la confirmation que des Données Personnelles
                       vous concernant sont ou ne sont pas traitées et, lorsqu'elles le sont,
                       d’accéder à ces données ;&nbsp;
                   </li>
                   <li>
                       de faire rectifier et, le cas échéant, de faire compléter vos Données
                       Personnelles qui sont inexactes ou incomplètes ;&nbsp;
                   </li>
                   <li>
                       de faire effacer vos Données Personnelles dans certaines circonstances
                       ;&nbsp;
                   </li>
                   <li>
                       de faire limiter le traitement de vos Données Personnelles dans certaines
                       circonstances ;<em>&nbsp;</em>
                   </li>
                   <li>
                       de vous opposer, pour des raisons tenant à votre situation particulière,
                       au traitement de vos Données Personnelles, fondé sur les intérêts
                       légitimes d’AXA ASSISTANCE. AXA ASSISTANCE ne traite plus les Données
                       Personnelles, à moins qu'elle ne démontre qu'il existe des motifs
                       légitimes et impérieux pour le traitement qui prévalent sur les intérêts
                       et les droits et libertés de la personne concernée ;&nbsp;
                   </li>
                   <li>
                       de vous opposer au traitement de vos Données Personnelles à des fins de
                       marketing direct, y compris au profilage effectué à des fins de marketing
                       direct ;&nbsp;
                   </li>
                   <li>
                       de ne pas faire l’objet d’une décision fondée exclusivement sur un
                       traitement &nbsp;automatisé, y compris le profilage, produisant des effets
                       juridiques la concernant ou l’affectant de manière significative ;
                       toutefois, si &nbsp;ce traitement automatisé est nécessaire à la
                       conclusion ou à l'exécution d'un contrat, vous avez le droit d'obtenir une
                       intervention humaine de la part d’AXA ASSISTANCE, d'exprimer votre point
                       de vue et de contester la décision d’AXA ASSISTANCE;&nbsp;
                   </li>
                   <li>
                       de recevoir vos Données Personnelles fournies à AXA ASSISTANCE, dans un
                       format structuré, couramment utilisé et lisible par machine ; de
                       transmettre ces données à un autre responsable du traitement, lorsque (i)
                       le traitement de vos Données Personnelles est fondé sur son consentement
                       ou pour les besoins de l’exécution d’un contrat et (ii) le traitement est
                       effectué à l'aide de procédés automatisés ; et d'obtenir que vos Données
                       Personnelles soient transmises directement d’AXA ASSISTANCE à un
                       responsable du traitement, lorsque cela est techniquement possible ;&nbsp;
                   </li>
                   <li>
                       de retirer votre consentement à tout moment, sans préjudice des
                       traitements effectués de manière licite avant le retrait de celui, lorsque
                       le traitement de vos Données Personnelles est fondé sur son
                       consentement.&nbsp;
                   </li>
               </ul>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".95pt",
                       marginLeft: "36.0pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   <span style={{ fontSize: 15 }}>&nbsp;</span>
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>CONTACTER AXA ASSISTANCE &nbsp;</h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Vous pouvez contacter AXA ASSISTANCE pour exercer vos droits aux adresses
                   suivantes. Votre demande doit être accompagné d'une photocopie recto verso
                   de la carte d'identité (ou de tout document d’identité) :&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "1.1pt",
                       marginLeft: "35.9pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <ul style={{ listStyleType: "undefined margin-left: 42.8px" }}>
                   <li>
                       <u>par courrier postal:</u> &nbsp;
                   </li>
               </ul>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "286.0pt",
                       marginBottom: ".25pt",
                       marginLeft: "54.0pt"
                   }}
               >
                   Data Protection Officer&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "286.0pt",
                       marginBottom: ".25pt",
                       marginLeft: "54.0pt"
                   }}
               >
                   Inter Partner Assistance S.A.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "54.0pt"
                   }}
               >
                   Boulevard du Régent 7
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "54.0pt"
                   }}
               >
                   1000 Bruxelles&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "1.15pt",
                       marginLeft: "53.5pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <ul style={{ listStyleType: "undefined margin-left: 42.8px" }}>
                   <li>
                       <u>par courrier électronique:</u> &nbsp;
                   </li>
               </ul>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: "53.5pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   <u>
                       <span style={{ color: "blue" }}>dpo.BNL@axa-assistance.com</span>
                   </u>
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: "35.9pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   AXA ASSISTANCE traitera les demandes dans les délais prévus par la loi. Sauf
                   demande manifestement infondée ou excessive, aucun paiement ne sera exigé
                   pour le traitement de ses demandes.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: "35.9pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".9pt",
                       marginLeft: "35.9pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <h1 style={{ marginLeft: "28.05pt" }}>INTRODUIRE UNE PLAINTE&nbsp;</h1>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Si vous estimez qu’AXA ASSISTANCE ne respecte pas la règlementation en la
                   matière, vous êtes invité.e à consulter AXA ASSISTANCE en priorité.&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Vous pouvez aussi introduire une réclamation auprès de l’Autorité de
                   Protection des Données Personnelles à l’adresse suivante :&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: "0cm",
                       marginLeft: "42.6pt",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   AUTORITE DE PROTECTION DE DONNEES&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Rue de la Presse, 35&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   1000 Bruxelles&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "272.7pt",
                       marginBottom: "0cm",
                       marginLeft: "35.75pt",
                       textAlign: "left",
                       lineHeight: "100%"
                   }}
               >
                   Tél. + 32 2 274 48 00&nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "270.55pt",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Email :&nbsp;
                   <a href="mailto:contact@apd-gba.be">
                       <span style={{ color: "black", textDecoration: "none" }}>
                           contact@apd-gba.be
                       </span>
                   </a>
               </p>
               <p
                   style={{
                       margin: "0cm",
                       textAlign: "left",
                       textIndent: "0cm",
                       lineHeight: "107%"
                   }}
               >
                   &nbsp;
               </p>
               <p
                   style={{
                       marginTop: "0cm",
                       marginRight: "0cm",
                       marginBottom: ".25pt",
                       marginLeft: "35.75pt"
                   }}
               >
                   Vous pouvez également déposer une plainte auprès du tribunal de première
                   instance de votre domicile. &nbsp;
               </p>
           </>

        </div>
    );
};

export default PrivacyPolicyFR;