
const PrivacyPolicyNL = () => {
    return (
        <div align="left" >
            <>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "25.9pt",
                        textAlign: "left",
                        lineHeight: "107%"
                    }}
                >
                    <strong>
                        <span style={{ fontSize: 32, color: "#002368" }}>
                            HOMECARE
                        </span>
                    </strong>
                </p>
                <br/>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "7.0pt",
                        marginLeft: "25.9pt",
                        textAlign: "left",
                        lineHeight: "107%"
                    }}
                >
                    <strong>
                        <span style={{ fontSize: 32, color: "#002368" }}>
                            Privacybeleid&nbsp;
                        </span>
                    </strong>
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "28.3pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p style={{ marginRight: "0cm" }}>
                    Dit document vormt het beleid inzake de verwerking van persoonsgegevens dat
                    van toepassing is op het gebruik van de Web Applicatie.
                </p>
                <p style={{ marginRight: "0cm" }}>&nbsp;</p>
                <h1>ACTUALISERING VAN DIT BELEID</h1>
                <p style={{ marginLeft: "36.0pt" }}>
                    <span style={{ fontSize: 13, fontFamily: "Calibri", color: "black" }}>
                        AXA ASSISTANCE kan dit Beleid bijwerken naar aanleiding van juridische,
                        technische of commerciële wijzigingen. Wanneer er updates worden gemaakt,
                        zal AXA ASSISTANCE de nodige maatregelen nemen om u te informeren, gezien
                        het belang van deze wijzigingen. Waar dit wettelijk vereist is, zal AXA
                        ASSISTANCE uw toestemming vragen voor belangrijke wijzigingen in de
                        verwerking van uw persoonsgegevens. Dit beleid is voor het laatst
                        bijgewerkt op 28 AUGUST 2024.
                    </span>
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>
                    WETTELIJKE KENNISGEVING - GEGEVENSBEHEERDER&nbsp;
                </h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    SA INTERPARTNER BIJSTAND &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Lid van de AXA ASSISTANCE-groep&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Maatschappelijke zetel: Regentenlaan 7, 1000 Brussel, &nbsp; Ingeschreven
                    bij de Kruispuntbank van Ondernemingen onder nr. 0415.591.055, RPM Brussel
                    Verzekeringsmaatschappij erkend onder code 0487&nbsp;
                </p>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    (hierna <em>"AXA ASSISTANCE" genoemd</em>).&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "56.65pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>DEFINITIES&nbsp;</h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    De Uitgever vestigt de aandacht van de Gebruikers van zijn site op de
                    volgende punten: &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "1.1pt",
                        marginLeft: "36.0pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <ul style={{ listStyleType: "undefined margin-left: 53.85px" }}>
                    <li>
                        <em>"Webapplicatie"&nbsp;</em>verwijst naar <em>HOMECARE</em>.&nbsp;
                    </li>
                    <li>
                        <em>"AXA ASSISTANCE"&nbsp;</em>verwijst naar SA INTER PARTNER ASSISTANCE,
                        zoals hierboven beschreven;&nbsp;
                    </li>
                    <li>
                        <em>"Gegevens"&nbsp;</em>verwijst naar persoonlijke gegevens;&nbsp;
                    </li>
                    <li>
                        <em>"Uitgever"&nbsp;</em>betekent AXA ASSISTANCE; &nbsp;
                    </li>
                    <li>
                        <em>"Wetgeving"&nbsp;</em>betekent de Belgische, Europese en
                        internationale wet- en regelgeving inzake de bescherming van de
                        persoonlijke levenssfeer en de verwerking van persoonsgegevens, in het
                        bijzonder de GDPR en de Belgische wetten en besluiten ter aanvulling van
                        deze Verordening;&nbsp;
                    </li>
                    <li>
                        <em>"Gegevensbeheerder" betekent&nbsp;</em>de gegevensbeheerder zoals
                        gedefinieerd door de RGPD; de gegevensbeheerder is AXA ASSISTANCE ;&nbsp;
                    </li>
                    <li>
                        <em>"GDPR":&nbsp;</em>Verordening (EU) 2016/679 van het Europees Parlement
                        en de Raad van 27 april 2016 betreffende de bescherming van natuurlijke
                        personen in verband met de verwerking van persoonsgegevens en betreffende
                        het vrije verkeer van die gegevens en tot intrekking van Richtlijn
                        95/46/EG ;&nbsp;
                    </li>
                    <li>
                        <em>"Onderaannemer" betekent&nbsp;</em>de onderaannemer, zoals
                        gedefinieerd door de GDPR;&nbsp;
                    </li>
                    <li>
                        <em>"Gebruiker"&nbsp;</em>betekent iedere persoon die gebruik maakt van de
                        Mobiele Applicatie; &nbsp;&nbsp;
                    </li>
                    <li>
                        <em>"U"&nbsp;</em>of <em>"Begunstigde"&nbsp;</em>verwijst naar de
                        begunstigde van Bijstandsdiensten (zoals gedefinieerd in de contractuele
                        voorwaarden van uw contract of polis), die toegang heeft tot de Mobiele
                        Applicatie, deze raadpleegt en gebruikt voor de implementatie van
                        Bijstandsdiensten.&nbsp;
                    </li>
                </ul>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>DOEL VAN DE VERWERKING&nbsp;</h1>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "36.0pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    De persoonsgegevens die u via de Webapplicatie aan AXA ASSISTANCE verstrekt,
                    kunnen door AXA ASSISTANCE voor de volgende doeleinden worden verwerkt:
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "1.1pt",
                        marginLeft: "7.7pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <ul style={{ listStyleType: "undefined margin-left: 53.85px" }}>
                    <li>Het leveren van hulpdiensten,&nbsp;</li>
                    <li>
                        De ontwikkeling, het beheer, de aanpassing en het bijwerken van de Mobiele
                        Applicatie en/of de functionaliteiten ervan,&nbsp;
                    </li>
                    <li>
                        analyse (met inbegrip van technische of statistische analyse) van het
                        gebruik van de Mobiele Applicatie en/of de functionaliteiten en
                        verbindingen met de Applicatie,
                    </li>
                    <li>beheer van het personeelsdossier, &nbsp;</li>
                    <li>klantenservice, &nbsp;</li>
                    <li>fraude op te sporen, te voorkomen en te bestrijden, &nbsp;</li>
                    <li>
                        de strijd tegen het witwassen van geld en de financiering van terrorisme,
                        &nbsp;
                    </li>
                    <li>portfoliomonitoring, &nbsp;</li>
                    <li>
                        naleving van de toepasselijke wetgeving (belastingen, sociale zekerheid,
                        boekhouding, enz.) bijstandsdiensten in het kader van uw contract of
                        verzekeringspolis,
                    </li>
                    <li>statistische onderzoeken.&nbsp;</li>
                </ul>
                <p style={{ marginRight: "0cm" }}>&nbsp;</p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "34.0pt"
                    }}
                >
                    Voor zover de mededeling van persoonsgegevens noodzakelijk is om de
                    hierboven opgesomde doeleinden te bereiken, kunnen persoonsgegevens worden
                    meegedeeld aan andere bedrijven die lid zijn van de AXA Groep, aan bedrijven
                    en/of personen die met hen in contact staan (advocaten, deskundigen, medisch
                    adviseurs, herverzekeraars, medeverzekeraars, verzekeringstussenpersonen,
                    dienstverleners, andere verzekeringsmaatschappijen, vertegenwoordigers,
                    tariefcontrolekantoren, schaderegelingskantoren, Datassur).
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "34.0pt"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "34.0pt"
                    }}
                >
                    Deze gegevens kunnen ook worden meegedeeld aan de toezichthoudende
                    autoriteiten, aan de bevoegde overheidsdiensten en aan elke andere openbare
                    of particuliere instantie waarmee AXA ASSISTANCE persoonlijke gegevens moet
                    uitwisselen in overeenstemming met de geldende wetgeving.
                </p>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>
                    GEGEVENS VERWERKT VIA DE WEBTOEPASSING
                </h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    AXA ASSISTANCE kan via de Web Applicatie de volgende Persoonlijke Gegevens
                    verzamelen: uw voor- en achternaam, uw telefoonnummer, uw adres, uw
                    e-mailadres, het type van de gevraagde dienst, uw IP-adres, uw taal, uw
                    verbindingsgegevens, evenals gegevens met betrekking tot gebeurtenissen die
                    verband houden met de apparaten die u gebruikt (systeemactiviteit, datum en
                    tijd van verbinding, hardwareparameters, technische problemen, enz.)&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>
                    RECHTSGRONDSLAG VOOR VERWERKING&nbsp;
                </h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "1.5pt",
                        textAlign: "center",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    AXA ASSISTANCE verwerkt uw Persoonsgegevens op de volgende
                    rechtsgronden:&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "1.15pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <ul style={{ listStyleType: "undefined margin-left: 72.3px" }}>
                    <li>
                        De uitvoering van uw verzekeringscontract of polis en de algemene
                        gebruiksvoorwaarden van de Mobiele Applicatie: om u hulpdiensten te
                        verlenen, om uw contract of polis te beheren of uit te voeren en om de
                        Mobiele Applicatie te beheren,&nbsp;
                    </li>
                    <li>
                        Het nastreven van de rechtmatige belangen van AXA ASSISTANCE: om de
                        Mobiele Applicatie te beheren, om technische of statistische analyses uit
                        te voeren, om de relatie met de verzekerde, de Begunstigde of de Gebruiker
                        te beheren, om fraude op te sporen, te voorkomen en te bestrijden, om haar
                        portefeuille te bewaken;&nbsp;
                    </li>
                    <li>
                        Voldoen aan wettelijke en reglementaire verplichtingen: met het oog op de
                        naleving en toepassing van de toepasselijke wetgeving (belastingen,
                        sociale zekerheid, boekhouding, enz.) op de bijstandsdiensten die worden
                        geleverd in het kader van uw contract of verzekeringspolis.&nbsp;
                    </li>
                </ul>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    De Persoonsgegevens die AXA ASSISTANCE u vraagt te verstrekken zijn
                    noodzakelijk voor een goed gebruik van de Mobiele Applicatie en gerelateerde
                    assistentiediensten. Het niet verstrekken van deze gegevens kan het
                    onmogelijk of moeilijk maken om de Mobiele Applicatie, de functionaliteiten
                    ervan en/of de gerelateerde assistentiediensten af te sluiten of er goed
                    gebruik van te maken.&nbsp;
                </p>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1>NOODZAAK OM PERSOONSGEGEVENS TE VERSTREKKEN</h1>
                <p style={{ marginLeft: "27.55pt" }}>
                    <span style={{ fontSize: 13, fontFamily: "Calibri", color: "black" }}>
                        De persoonsgegevens met betrekking tot de betrokkene die AXA Partners u
                        vraagt te verstrekken, zijn noodzakelijk voor het sluiten en uitvoeren van
                        de verzekeringsovereenkomst. Het niet verstrekken van deze gegevens kan
                        het sluiten of de goede uitvoering van de verzekeringsovereenkomst
                        onmogelijk maken.
                    </span>
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".95pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>OVERDRACHT VAN GEGEVENS &nbsp;</h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Voor zover de mededeling van persoonsgegevens noodzakelijk is om de
                    hierboven opgesomde doeleinden te bereiken, kan AXA ASSISTANCE uw
                    persoonsgegevens doorgeven aan andere personen, hetzij andere bedrijven van
                    de AXA Groep of derden van andere bedrijven van de AXA Groep, aan bedrijven
                    en/of personen die met hen in contact staan (hulpverleners, advocaten,
                    deskundigen, medisch adviseurs, herverzekeraars, medeverzekeraars,
                    verzekeringstussenpersonen, dienstverleners, andere
                    verzekeringsmaatschappijen, vertegenwoordigers, acceptatiekantoren,
                    schaderegelingskantoren, Datassur). De andere bedrijven van de AXA Groep, de
                    bedrijven en/of personen die met hen in contact staan en aan wie
                    Persoonsgegevens worden meegedeeld, kunnen zowel binnen als buiten de
                    Europese Unie gevestigd zijn.&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    In het geval van overdracht van persoonsgegevens aan derden buiten de
                    Europese Unie, leeft AXA ASSISTANCE de geldende wettelijke en reglementaire
                    bepalingen inzake dergelijke overdrachten na. In het bijzonder waarborgt zij
                    een adequaat beschermingsniveau voor persoonsgegevens die op deze wijze
                    worden doorgegeven op basis van alternatieve mechanismen die door de
                    Europese Commissie zijn ingesteld, zoals standaard contractuele clausules,
                    of de
                    <a href="https://www.axa.com/fr/a-propos-d-axa/confidentialite-donnees#tab=nos-engagementshttps%3A%2F%2F">
                        &nbsp;bindende bedrijfsregels&nbsp;
                    </a>
                    <a
                        id="_anchor_1"
                        href="#_msocom_1"
                        language="JavaScript"
                        name="_msoanchor_1"
                    >
                        [LV1]
                    </a>{" "}
                    <span style={{ fontSize: 11, lineHeight: "103%" }}>
                        <a
                            id="_anchor_2"
                            href="#_msocom_2"
                            language="JavaScript"
                            name="_msoanchor_2"
                        >
                            [BS2]
                        </a>
                        &nbsp;
                    </span>
                    van de AXA Groep in geval van doorgifte binnen de groep (
                    <em>Mon. B.&nbsp;</em>6/10/2014, p. 78547).&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Deze gegevens kunnen ook worden meegedeeld aan de toezichthoudende
                    autoriteiten, de bevoegde overheidsdiensten en elke andere openbare of
                    particuliere organisatie waarmee AXA ASSISTANCE persoonsgegevens moet
                    uitwisselen in overeenstemming met de toepasselijke wetgeving.&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>BEWAREN VAN GEGEVENS&nbsp;</h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    AXA ASSISTANCE bewaart de Persoonsgegevens die via de mobiele Applicatie
                    zijn verzameld voor de gehele duur van het gebruik van deze Applicatie en de
                    levering van de bijbehorende hulpverleningsdiensten. Deze periode kan worden
                    verlengd met de wettelijke bewaartermijn (met name in boekhoudkundige of
                    fiscale aangelegenheden) of met de verjaringstermijn om verzoeken of enig
                    verhaal te kunnen behandelen die kunnen worden geïnitieerd na het einde van
                    het gebruik van de Mobiele Applicatie of na het afsluiten van het
                    schadedossier.&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "36.0pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    AXA ASSISTANCE verbindt zich ertoe uw Persoonlijke Gegevens veilig,
                    vertrouwelijk en nauwkeurig te bewaren gedurende de periode van gebruik die
                    hierin is toegestaan. Aan het einde van de bewaarperiode worden uw
                    Persoonlijke Gegevens anoniem gemaakt of vernietigd.&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: ".5pt",
                        textAlign: "left",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    <span style={{ fontSize: 19, color: "red" }}>
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                    </span>
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>VERTROUWELIJKHEID&nbsp;</h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    AXA ASSISTANCE heeft alle nodige maatregelen genomen om de vertrouwelijkheid
                    van de persoonsgegevens te bewaren en om deze te beschermen tegen
                    ongeoorloofde toegang, misbruik, wijziging of verwijdering.&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Daartoe volgt AXA ASSISTANCE de normen voor beveiliging en continuïteit van
                    de dienstverlening en evalueert zij regelmatig het beveiligingsniveau van
                    haar processen, systemen en toepassingen en die van haar partners.&nbsp;
                </p>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>
                    RECHTEN VAN DE BETROKKEN PERSOON&nbsp;
                </h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Je hebt het recht om:&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "1.15pt",
                        marginLeft: "17.9pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <ul style={{ listStyleType: "undefined margin-left: 53.85px" }}>
                    <li>
                        om van AXA ASSISTANCE de bevestiging te krijgen dat Persoonsgegevens die
                        op u betrekking hebben al dan niet worden verwerkt en, indien dit wel het
                        geval is, om toegang te krijgen tot deze gegevens;&nbsp;
                    </li>
                    <li>
                        om uw Persoonsgegevens te laten rectificeren en, indien nodig, aan te
                        vullen als ze onjuist of onvolledig zijn;&nbsp;
                    </li>
                    <li>
                        om uw Persoonsgegevens in bepaalde omstandigheden te laten
                        verwijderen;&nbsp;
                    </li>
                    <li>
                        om de verwerking van uw Persoonsgegevens in bepaalde omstandigheden te
                        beperken<em>;&nbsp;</em>
                    </li>
                    <li>
                        om bezwaar te maken, om redenen die verband houden met uw bijzondere
                        situatie, tegen de verwerking van uw Persoonsgegevens, op grond van de
                        gerechtvaardigde belangen van AXA ASSISTANCE. AXA ASSISTANCE zal de
                        Persoonsgegevens niet langer verwerken, tenzij zij kan aantonen dat er
                        dwingende gerechtvaardigde gronden zijn voor de verwerking die zwaarder
                        wegen dan de belangen en de rechten en vrijheden van de betrokkene;&nbsp;
                    </li>
                    <li>
                        bezwaar te maken tegen de verwerking van uw Persoonsgegevens voor direct
                        marketingdoeleinden, inclusief profilering voor direct
                        marketingdoeleinden;&nbsp;
                    </li>
                    <li>
                        niet het onderwerp te zijn van een besluit dat uitsluitend is gebaseerd op
                        geautomatiseerde verwerking, met inbegrip van profilering, die
                        rechtsgevolgen heeft die hem betreffen of die hem in aanmerkelijke mate
                        treffen; indien deze geautomatiseerde verwerking echter noodzakelijk is
                        voor het sluiten of uitvoeren van een contract, hebt u het recht om
                        menselijke tussenkomst van AXA ASSISTANCE te verkrijgen, uw standpunt
                        kenbaar te maken en het besluit van AXA ASSISTANCE te betwisten;&nbsp;
                    </li>
                    <li>
                        om uw Persoonsgegevens te ontvangen die aan AXA ASSISTANCE zijn verstrekt,
                        in een gestructureerd, algemeen gebruikt en machineleesbaar formaat; om
                        deze gegevens door te geven aan een andere gegevensbeheerder, wanneer (i)
                        de verwerking van uw Persoonsgegevens is gebaseerd op haar toestemming of
                        voor de behoeften van de uitvoering van een contract en (ii) de verwerking
                        wordt uitgevoerd met behulp van geautomatiseerde processen; en om te
                        verkrijgen dat uw Persoonsgegevens rechtstreeks door AXA ASSISTANCE worden
                        doorgegeven aan een gegevensbeheerder, wanneer dit technisch mogelijk
                        is;&nbsp;
                    </li>
                    <li>
                        om uw toestemming te allen tijde in te trekken, zonder afbreuk te doen aan
                        de verwerking die rechtmatig is uitgevoerd voorafgaand aan de intrekking
                        van de toestemming, wanneer de verwerking van uw Persoonsgegevens is
                        gebaseerd op uw toestemming.&nbsp;
                    </li>
                </ul>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".95pt",
                        marginLeft: "36.0pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    <span style={{ fontSize: 15 }}>&nbsp;</span>
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>
                    CONTACT OPNEMEN MET AXA ASSISTANCE &nbsp;
                </h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    U kunt contact opnemen met AXA ASSISTANCE om uw rechten uit te oefenen op de
                    volgende adressen. Uw verzoek moet vergezeld gaan van een fotokopie van
                    beide zijden van uw identiteitskaart (of een ander identiteitsbewijs):&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "1.1pt",
                        marginLeft: "35.9pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <ul style={{ listStyleType: "undefined margin-left: 42.8px" }}>
                    <li>
                        <u>per post</u>: &nbsp;
                    </li>
                </ul>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "286.0pt",
                        marginBottom: ".25pt",
                        marginLeft: "54.0pt",
                        textAlign: "left"
                    }}
                >
                    Functionaris voor gegevensbescherming&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "286.0pt",
                        marginBottom: ".25pt",
                        marginLeft: "54.0pt"
                    }}
                >
                    Inter Partner Assistance S.A.&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "54.0pt"
                    }}
                >
                    Boulevard du Régent 7
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "54.0pt"
                    }}
                >
                    1000 Brussel&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "1.15pt",
                        marginLeft: "53.5pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <ul style={{ listStyleType: "undefined margin-left: 42.8px" }}>
                    <li>
                        <u>per e-mail</u>: &nbsp;
                    </li>
                </ul>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "53.5pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    <u>
                        <span style={{ color: "blue" }}>dpo.BNL@axa-assistance.com&nbsp;</span>
                    </u>
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "35.9pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    AXA ASSISTANCE zal de verzoeken behandelen binnen de wettelijk vastgestelde
                    termijnen. Tenzij het verzoek duidelijk ongegrond of buitensporig is, zal
                    voor de behandeling van deze verzoeken geen betaling worden gevraagd.&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "35.9pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".9pt",
                        marginLeft: "35.9pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <h1 style={{ marginLeft: "28.05pt" }}>EEN KLACHT INDIENEN&nbsp;</h1>
                <p
                    style={{
                        margin: "0cm",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Als u van mening bent dat AXA ASSISTANCE de relevante regelgeving niet
                    naleeft, wordt u uitgenodigd om AXA ASSISTANCE met voorrang te
                    raadplegen.&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    U kunt ook een klacht indienen bij de Autoriteit Persoonsgegevens op het
                    volgende adres&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "0cm",
                        marginLeft: "42.6pt",
                        textAlign: "left",
                        textIndent: "0cm",
                        lineHeight: "107%"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    GEGEVENSBESCHERMINGSAUTORITEIT&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Rue de la Presse, 35&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    1000 Brussel&nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "272.7pt",
                        marginBottom: "0cm",
                        marginLeft: "35.75pt",
                        textAlign: "left",
                        lineHeight: "100%"
                    }}
                >
                    Tel. + 32 2 274 48 00
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "272.7pt",
                        marginBottom: "0cm",
                        marginLeft: "35.75pt",
                        textAlign: "left",
                        lineHeight: "100%"
                    }}
                >
                    &nbsp;Fax + 32 2 274 48 35
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "270.55pt",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Email :&nbsp;
                    <a href="mailto:contact@apd-gba.be">
                        <span style={{ color: "black", textDecoration: "none" }}>
                            contact@apd-gba.be
                        </span>
                    </a>
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "270.55pt",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".25pt",
                        marginLeft: "35.75pt"
                    }}
                >
                    Je kunt ook een klacht indienen bij de rechtbank van eerste aanleg. &nbsp;
                </p>
            </>
        </div>
    );
};

export default PrivacyPolicyNL;